import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PageLayout from '../Components/PageLayout';
import Accordion from 'react-bootstrap/Accordion';

const generalFaqs = [
  {
    question: 'What is CoinBazaar Digital powered by eBullion?',
    answer: (
      <div>
        CoinBazaar Digital and eBullion have collaborated to offers investment platform that provides 24/7 access to buy, sell and gift precious metals online like Gold, Platinum, Palladium, and Silver on the go.
      </div>
    ),
  },
  {
    question: 'Why choose to invest in digital precious metals through CoinBazaar Digital?',
    answer: (
      <div>
        <div>
          CoinBazaar Digital powered by eBullion Pvt Ltd is an investment platform that enables an individual to invest in key precious metals. This revolutionary platform offers an unparalleled opportunity for an individual to buy and sell, gift and invest in SIP in four precious metals (Gold, Silver, Platinum, and Palladium), with a minimal investment of just Rs. 100, at international spot prices fetched real-time from globally trusted data providers.
        </div>
        <br></br>
        <div>
          eBullion assures your investment is backed by physical metal which is kept in a top of the line secured and insured SEBI-regulated vaults.  A distinctive feature of eBullion is its self-regulated model where a customer’s precious metal and funds are secured wherein the customer funds are held in an escrow bank account.
        </div>
        <br></br>
        <div>
          eBullion is powered by Hindustan Platinum Pvt Ltd. which has 60 plus years of experience in refining precious metals accredited by LBMA (London Bullion Market Association) and LLPM (London Platinum and Palladium Market) for Good Delivery.
        </div>
        <br></br>
        <div>
          We at eBullion assure you that your investment is in safe hands and grows with each passing day. 
        </div>
      </div>
    ),
  },
  {
    question:
      'Can minors buy digital metal online?',
    answer: (
      <div>
        Any individual below the age of 18 is not eligible to buy or sell precious metals online.
      </div>
    ),
  },
  {
    question: 'What are the eligibility criteria for registering?',
    answer: (
      <div>
        Any Indian citizen with a bank account, a valid PAN and an Indian mobile number is eligible to enroll with CoinBazaar Digital.
      </div>
    ),
  },
  {
    question: 'Is it mandatory to upload my PAN card?',
    answer: (
      <div>
        No, a PAN card is not necessary for signup but it will be prompted at the time of first purchase.
      </div>
    ),
  },
  {
    question: 'What is the minimum & maximum amount needed to buy metals on CoinBazaar Digital powered eBullion?',
    answer: (
      <div>
        The minimum amount of purchase is ₹100. There is no limit on the maximum amount as long as KYC requirements are complied with. The SIP plan can be initiated with a minimum investment of 100 Rupees while accommodating a maximum investment limit of 99,999 Rupees with an option to invest in either weekly or monthly intervals. 
      </div>
    ),
  },
  {
    question: 'What is the purity of the precious metals in offering to customers?',
    answer: (
      <div>
        eBullion sources precious metals from London Good Delivery Refiners only. The purity of the precious metal is as follows:
        <br></br>
        <br></br>
        <table style={{border: '1px solid black', borderCollapse: 'collapse'}}>
          <tr style={{border: '1px solid black', borderCollapse: 'collapse'}}>
            <th style={{border: '1px solid black', borderCollapse: 'collapse', padding: '10px'}}>Metal</th>
            <th style={{border: '1px solid black', borderCollapse: 'collapse', padding: '10px'}}>Purity</th>
            <th style={{border: '1px solid black', borderCollapse: 'collapse', padding: '10px'}}>Metal</th>
            <th style={{border: '1px solid black', borderCollapse: 'collapse', padding: '10px'}}>Purity</th>
          </tr>
          <tr style={{border: '1px solid black', borderCollapse: 'collapse'}}>
            <td style={{border: '1px solid black', borderCollapse: 'collapse', padding: '10px'}}>Gold</td>
            <td style={{border: '1px solid black', borderCollapse: 'collapse', padding: '10px'}}>99.99%</td>
            <td style={{border: '1px solid black', borderCollapse: 'collapse', padding: '10px'}}>Platinum</td>
            <td style={{border: '1px solid black', borderCollapse: 'collapse', padding: '10px'}}>99.95%</td>
          </tr>
          <tr style={{border: '1px solid black', borderCollapse: 'collapse'}}>
            <td style={{border: '1px solid black', borderCollapse: 'collapse', padding: '10px'}}>Silver</td>
            <td style={{border: '1px solid black', borderCollapse: 'collapse', padding: '10px'}}>99.99%</td>
            <td style={{border: '1px solid black', borderCollapse: 'collapse', padding: '10px'}}>Palladium</td>
            <td style={{border: '1px solid black', borderCollapse: 'collapse', padding: '10px'}}>99.95%</td>
          </tr>
        </table>
      </div>
    ),
  },
  {
    question: 'Do you charge for the storage of my metal?',
    answer: (
      <div>
        We offer free storage for up to 3 years, after which we reserve the right to charge a yearly nominal fee for the storage of your metal, which would be in the range of around 0.3 - 0.4% (per annum) of your metal balance.
      </div>
    ),
  },
  {
    question: 'What certification does eBullion have on physical precious metals?',
    answer: (
      <div>
        eBullion procures all the physical metal from Hindustan Platinum and has been refining Silver, Platinum and Palladium for the past 60 plus years. Hindustan Platinum is among a few companies globally to be listed on the London Platinum and Palladium Market’s (LPPM) Good Delivery List for Platinum and Palladium and on the London Bullion Market Association’s (LBMA) Good Delivery List for Silver.
      </div>
    ),
  },
  {
    question: 'Where is the physical metal stored?',
    answer: (
      <div>
        We have partnered with Sequel Logistics, one of the world’s largest security companies, to store its precious metals. Sequel’s High-Grade Vaults are certified by SEBI & LBMA (London Bullion Market Association) for precious metals storage. All the metal stored in Sequel vaults is insured and secured.
      </div>
    ),
  },
  {
    question: 'Can I add a bank account with a different name than my PAN card?',
    answer: (
      <div>
        Please use the same name as on your PAN card, as this will aid with the verification of your bank information.
      </div>
    ),
  },
  {
    question: 'Why is KYC needed?',
    answer: (
      <div>
        Safeguarding your precious metals is and will always be our top priority. We put a lot of effort into making improvements to stop account abuse and illegal access to your money. KYC assures that your transactions will be safer.
      </div>
    ),
  },
  {
    question: 'What payment modes are accepted?',
    answer: (
      <div>
        Customers can make the payment through different digital payment modes like net banking, UPI, and CoinBazaar Digital wallet through Virtual account facility. 
      </div>
    ),
  },
  {
    question: 'Where do I see my transactions?',
    answer: (
      <div>
        Your transactions will be available at all times which you can view online by clicking on the Transaction History tab.
      </div>
    ),
  },
  {
    question: 'How much time does it take to get the money transferred to my bank after selling my Digital Gold?',
    answer: (
      <div>
        Once the metal is sold, the money will be credited to your bank account within 24 hours.
        <br/>
       <br>
       </br>

        <b>We are just a call away please feel free to contact us at +91-8169860012 or write us at <a href='info@coinbazaar.in'>info@coinbazaar.in</a> </b>
      </div>
    ),
  },
];

const sipFaqs = [
  {
    question: 'What is SIP in CoinBazaar Digital?',
    answer: (
      <div>
       Systematic Investment Plan or SIP scheme where you can invest in Gold, Silver, Platinum and Palladium precious metals for a fixed amount at a fixed selected interval (Weekly/Monthly).
      </div>
    ),
  },
  {
    question: 'Why should one choose the SIP scheme by CoinBazaar Digital?',
    answer: (
      <div>
       It enables you to accumulate Gold, Silver, Platinum and Palladium in smaller quantities through periodic installments leveraging the price benefit.
      </div>
    ),
  },
  {
    question:
      'What price is considered at the time of installment deduction of SIP?',
    answer: (
      <div>
       The periodic installment is used on your selected date to buy precious metals at the price prevailing at the time of fund deduction.
      </div>
    ),
  },
  {
    question:
      'Can you choose multiple SIPs?',
    answer: (
      <div>
       Yes, you can choose to invest in multiple metals with different amounts simultaneously.
      </div>
    ),
  },
  {
    question:
      'What is the minimum amount of investment for SIP?',
    answer: (
      <div>
       With our promise to keep the investment to as low as ₹100. SIP investment can be started at ₹100 a week or every month. The maximum amount of SIP investment installment is Rs.99,999.
      </div>
    ),
  },
  {
    question:
      'What will happen if my bank account has insufficient funds when my SIP payment is due?',
    answer: (
      <div>
       We will attempt the transaction again once the next day, if it fails for any reason, including insufficient funds in the account. The SIP payment for that specific week or month is missed after two unsuccessful attempts. Note we do not charge any late fee or unsuccessful attempt fee. 
      </div>
    ),
  },
  {
    question:
      'Can the tenure of the SIP plan be extended?',
    answer: (
      <div>
       No, once a SIP plan has been successfully created, the tenure of the plan cannot be extended. However, you can get a new SIP Plan with only one click with your current SIP or after expiry of the ongoing SIP. 
      </div>
    ),
  },
  {
    question:
      'Where can I find the invoice for each order?',
    answer: (
      <div>
       All the transactions are displayed in your CoinBazaar Digital passbook. You can download the invoice from the passbook section in the dashboard. 
      </div>
    ),
  },
  {
    question:
      'How soon can I start investing in SIP and when does my SIP start?',
    answer: (
      <div>
       One can start investing in SIP right away, one can choose the date of installment deduction starting from the 5th day from the date of SIP initiation. 
       <br/>
       <br>
       </br>

        <b>We are just a call away please feel free to contact us at +91-8169860012 or write us at <a href='info@coinbazaar.in'>info@coinbazaar.in</a> </b>

      </div>
    ),
  },
];
const giftFaqs = [
  {
    question: 'What is CoinBazaar’s precious metal gifting?',
    answer: (
      <div>
        Precious metals can now be gifted to your loved ones. You can effortlessly gift gold, silver, platinum, and palladium digitally with our brand-new Precious Metal Gifting feature, transforming memorable occasions into enduring treasures.
      </div>
    ),
  },
  {
    question: 'How can I send digital precious metal as a gift?',
    answer: (
      <div>
        Please follow the below steps to gift precious metal.
        <br></br>
        <p><b>Step 1:</b>  Select your preferred metal on the gifting tab and enter the quantity in either grams or rupees.</p>
        <p><b>Step 2:</b> Enter the recipient's name, phone number, and personalized message</p>
        <p><b>Step 3:</b> Click the submit button. </p>
        <br></br>
        As soon as the gift is sent, you and the recipient will receive a text confirmation.
      </div>
    ),
  },
  {
    question: 'Is there a minimum amount for CoinBazaar Digital precious metal gifts?',
    answer: (
      <div>
        The digital precious metal gifting feature of allows you to gift precious metal for as low as ₹100.
      </div>
    ),
  },
  {
    question: 'How does the recipient redeem their CoinBazaar Digital precious metal gift?',
    answer: (
      <div>
        Once a gift is sent, the recipient receives an SMS and can also view it in the received gift section of the app.  The receiver then has to click the Accept Gift button in the Gift section.
      </div>
    ),
  },
  {
    question: 'Can the receiver reject the gift?',
    answer: (
      <div>
        The recipient has the option to either accept or reject the gift within 15 days from the date of receipt. 
      </div>
    ),
  },
  {
    question: "What happens if the recipient doesn't have an account on CoinBazaar Digital?",
    answer: (
      <div>
        Once the gift is sent, the recipient will receive a link to sign up through the website; once he/she creates an account, he/she will be able to view it in the received gift section.
      </div>
    ),
  },
  {
    question: "How secure is CoinBazaar’s Digital precious metal gifting?",
    answer: (
      <div>
        We assure you that every purchase you make with digital precious metal gifting is safe and secure. Our payment partner guarantees secure transactions, while our vaulting partner guarantees that your precious metal is physically secured and insured.
      </div>
    ),
  },
  {
    question: "Can I personalize my CoinBazaar’s Digital gift?",
    answer: (
      <div>
       Personal message feature allows users to add a special touch to their digital gifts. With this feature, you can craft heartfelt messages to accompany your digital gifts, making your gesture even more meaningful and personalized. 
      </div>
    ),
  },
  {
    question: "Is there an expiry date for CoinBazaar Digital precious metal gifts?",
    answer: (
      <div>
       Yes, the CoinBazaar Digital precious metal gift is only valid for 15 days after it's sent. If the gift is not actioned, it will be returned to the sender's metal balance by default.
       <br/>
       <br>
       </br>

        <b>We are just a call away please feel free to contact us at +91-8169860012 or write us at <a href='info@coinbazaar.in'>info@coinbazaar.in</a> </b>
      </div>
    ),
  },
];

const NewFaqPage = () => {
  const [selctedQues, setSelectedQues] = useState(null);
  const [faqTitle, setFaqTitle] = useState('general');
  const [faqs, setFaqs] = useState(generalFaqs);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const modalHeadingStyle = {
    fontWeight: 400,
    lineHeight: '34px',
    paddingTop: '34px',
    paddingBottom: '20px',
    color: '#30154F',
  };
  const crossIconStyle = {
    // paddingTop: "18px",
    cursor: 'pointer',
    position: 'absolute',
    top: '10px',
    right: '15px',
  };

  const answerStyle = {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    /* or 150% */

    color: '#000000',
  };

  useEffect(() => {
    if (faqTitle == 'sip') {
      setFaqs(sipFaqs);
    } else if (faqTitle == 'gift') {
      setFaqs(giftFaqs);
    } else {
      setFaqs(generalFaqs);
    }
  }, [faqTitle]);

  //   console.log("faqq",faqs)
  return (
    <PageLayout page='faqs'>
      <header style={{ marginTop: '150px' }}>
        <section>
          <h3 className='text-center' style={{ fontWeight: '700' }}>
            FAQ's
          </h3>
          <div
            style={{
              width: '4%',
              borderBottom: '5px solid #CEA538',
              margin: '9px auto',
              marginBottom: '35px',
              borderRadius:'9px'
            }}
          ></div>
        </section>
      </header>
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <button
                className={`btn ${
                  faqTitle == 'general' ? 'btn-submit' : 'btn-outline-submit'
                } m-3`}
                onClick={() => setFaqTitle('general')}
              >
                General
              </button>
            </div>
            <div className='col'>
              <button
                className={`btn ${
                  faqTitle == 'sip' ? 'btn-submit' : 'btn-outline-submit'
                } m-3`}
                onClick={() => setFaqTitle('sip')}
              >
                SIP
              </button>
            </div>
            <div className='col'>
              <button
                className={`btn ${
                  faqTitle == 'gift' ? 'btn-submit' : 'btn-outline-submit'
                } m-3`}
                onClick={() => setFaqTitle('gift')}
              >
                Gift
              </button>
            </div>
          </div>
        </div>
      </section>
      <section style={{  marginTop: '15px'}}>
        <div
          className='container'
          style={{
            height: '60vh',
            overflow: 'scroll',
            // scrollbarColor: 'transparent',
          }}
        >
          {faqs.map((item, index) => {
            return (
              <>
                <ul>
                  <li>
                    <h5 style={{ fontWeight: '600', marginBottom: '15px', fontSize:'24px' }}>
                      {item?.question}
                    </h5>
                    <p style={{fontSize:'16px',marginBottom: '25px'}}>{item?.answer}</p>
                  </li>
                </ul>
              </>
            );
          })}
        </div>
      </section>
    </PageLayout>
  );
};

export default NewFaqPage;
